import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { OrganizationService } from './organization.service';

@Injectable({providedIn: 'root'})
export class OrganizationGuard implements CanActivate {
  constructor(
    private organizationService: OrganizationService,
    private router: Router,
  ) {}

  async canActivate(routeSnapshot: ActivatedRouteSnapshot) {
    if (!this.organizationService.current$.getValue()) {
      const id = routeSnapshot.params.id === 'current' ? null : routeSnapshot.params.id ;
      await this.organizationService.initCurrentOrganization(id);
    }

    if (routeSnapshot.params.id === 'current' || routeSnapshot.params.id === 'null' || !routeSnapshot.params.id) {
      return this.router.createUrlTree(['organizations', this.organizationService.currentId$.getValue()]);
    }

    return true;
  }
}
