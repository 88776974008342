import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbThemeModule,
  NbLayoutModule,
  NbToastrModule,
  NbWindowModule,
  NbDialogModule,
  NbMenuModule,
  NbDatepickerModule,
  NbSidebarModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { NbAuthModule } from '@nebular/auth';
import { NbFirebasePasswordStrategy, NbFirebaseGoogleStrategy, NbFirebaseAuthModule } from '@nebular/firebase-auth';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ThemeModule } from './@theme/theme.module';
import { MatDialogModule } from '@angular/material/dialog';
import { SETTINGS } from '@angular/fire/firestore';
import { ORIGIN, REGION } from '@angular/fire/functions';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbFirebaseAuthModule,
    NbAuthModule.forRoot({
      strategies: [
        NbFirebasePasswordStrategy.setup({
          name: 'password'
        }),
        NbFirebaseGoogleStrategy.setup({
          name: 'google'
        })
      ],
      forms: {}
    }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    ThemeModule.forRoot(),
    MatDialogModule,
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: SETTINGS, useValue: environment.emulator ? {
        host: 'localhost:8080',
        ssl: false
      } : undefined
    },

    { provide: REGION, useValue: 'europe-west3' },

    { provide: ORIGIN, useValue: environment.emulator ? 'http://localhost:5001' : undefined }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
