import { Component, OnInit } from '@angular/core';
import { Organization, OrganizationService } from '../organization.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'up-organizations-switcher',
  template: `
    <p-dropdown
      class="dropdown"
      [options]="options$ | async"
      optionLabel="name"
      [ngModel]="selected"
      (onChange)="handleSelectedChange($event.value)"
      placeholder="Choose organization">
    </p-dropdown>
  `,
  styleUrls: ['./organizations-switcher.component.scss'],
})
export class OrganizationsSwitcherComponent implements OnInit {
  selected: Organization = null;
  options$ = this.organizationService.list$;

  constructor(
    private organizationService: OrganizationService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.selected = this.organizationService.current$.getValue();
  }

  async handleSelectedChange(value: Organization) {
    this.organizationService.setOrganization(value);

    const lastSegment = this.route.snapshot.firstChild.url[0].path;

    // special trick to force current component reload
    await this.router.navigate(['/organizations', value.id, 'dummy'], {skipLocationChange: true});
    await this.router.navigate(['/organizations', value.id, lastSegment]);
  }
}
