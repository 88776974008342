// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


import { FirebaseOptions } from '@angular/fire';

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyBmm-T2Oru-cmQ8QXK_Fdyp8Dg0_i90jYo",
  authDomain: "fresh-lens-290817.firebaseapp.com",
  databaseURL: "https://fresh-lens-290817.firebaseio.com",
  projectId: "fresh-lens-290817",
  storageBucket: "fresh-lens-290817.appspot.com",
  messagingSenderId: "216014015328",
  appId: "1:216014015328:web:913a0cd72154431226ec05",
  measurementId: "G-56CBNRF3TG"
};

export const environment = {
  production: false,
  emulator: false,
  firebase: firebaseConfig
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
