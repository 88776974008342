import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { UserService } from './user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { switchMap } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private toastrService: NbToastrService,
    private router: Router,
  ) {}

  canActivate() {
    return this.afAuth.user.pipe(
      switchMap(async (afaUser) => {
        if (afaUser) {
          const token = await afaUser.getIdTokenResult();

          if (!token.claims.trusted) {
            await this.afAuth.signOut();
            await this.router.navigate(['auth', 'login']);
            this.toastrService.warning('Your account is not activated. ' +
              'Please ask system administrator to activate your account.', 'Error', {
              destroyByClick: true,
              duration: null,
            });
            return false;
          }

          this.userService.setUser({
            roles: Object.keys(token.claims),
            email: afaUser.email,
            name: afaUser.displayName,
            id: afaUser.uid,
          });

          return true;
        }

        return this.router.createUrlTree(['auth', 'login']);
      }),
    );
  }
}
