import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { withFirebaseId } from '../utils/firebase.utils';


export interface Organization {
  id: string;
  name: string;
  domain: string;
}

@Injectable({providedIn: 'root'})
export class OrganizationService {
  current$ = new BehaviorSubject<Organization>(null);
  currentId$ = new BehaviorSubject<string>(null);
  list$: Observable<Organization[]> = this.firestore.collection<Organization>('organizations').get().pipe(map(({docs}) => {
    return docs.map<Organization>(withFirebaseId);
  }));

  constructor(private firestore: AngularFirestore) {}

  async initCurrentOrganization(id: string) {
    // TODO: here should be logic to choose organization depending on registered user
    // but currently just get a first one

    let doc;

    if (id) {
      doc = await this.firestore.collection<Organization>('organizations').doc(id).get().toPromise();
      if (!doc.exists) {
        throw new Error(`Requested organization not exists [${id}]`);
      }
    } else {
      const snapshot = await this.firestore.collection<Organization>('organizations').get().toPromise();
      doc = snapshot.docs[0];
    }

    const organization = withFirebaseId<Organization>(doc);

    this.setOrganization(organization);
  }

  setOrganization(organization: Organization) {
    this.current$.next(organization);
    this.currentId$.next(organization.id);
  }

  getCollectionPath(path: string) {
    return 'organizations/' + this.currentId$.getValue() + '/' + path;
  }
}
