import { QueryDocumentSnapshot } from '@angular/fire/firestore';

export type FirebaseDocumentData<T> = Omit<T, 'id'>;


export function withFirebaseId<T extends {id: string}>(snapshot: QueryDocumentSnapshot<any>): T {
  return {
    id: snapshot.id,
    ...snapshot.data() as FirebaseDocumentData<T>,
  } as T;
}
