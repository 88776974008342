import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { LicenseManager } from '@ag-grid-enterprise/core';
LicenseManager.setLicenseKey('CompanyName=Implemented Today LLC,LicensedGroup=ECP,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-012578,ExpiryDate=26_February_2022_[v2]_MTY0NTgzMzYwMDAwMA==ecfa22c31194c37be78cd85adfc2b49f');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
