import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

export interface User {
  roles: string[];
  email: string;
  name?: string;
  id: string;
}

@Injectable({providedIn: 'root'})
export class UserService {
  me$ = new BehaviorSubject<User>(null);
  items: any[];
  users: User[];
  readonly favoriteClientsAmount = 4;

  constructor(
    private db: AngularFirestore,
  ) {}

  get user() {
    return this.me$.value;
  }

  get me() {
    return this.me$.value;
  }

  loadUserData(uid: string): Observable<User> {
    return this.db
      .collection<Partial<User>>('users').doc(uid).get().pipe(
        map((snapshot) => {
          if (snapshot.exists) {
            return {
              id: snapshot.id,
              ...snapshot.data(),
            } as User;
          }
          return null;
        }),
      );
  }

  setUser(user: User) {
    this.me$.next(user);
  }
}
