import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { redirectLoggedInTo, canActivate } from '@angular/fire/auth-guard';
import { AuthGuard } from './auth/auth.guard';
import { OrganizationGuard } from './organizations/organization.guard';

const redirectLoggedInToPages = () => redirectLoggedInTo(['organizations']);

export const routes: Routes = [
  {
    path: 'organizations/:id',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard, OrganizationGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    ...canActivate(redirectLoggedInToPages),
  },
  {path: '', redirectTo: 'organizations/current', pathMatch: 'full'},
  {path: '**', redirectTo: 'organizations'},
];

const config: ExtraOptions = {
  useHash: false,
  relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
