import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService, NbThemeService, NbMediaBreakpointsService } from '@nebular/theme';

import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LayoutService } from '../../layout.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NbMenuItem } from '@nebular/theme/components/menu/menu.service';
import { Router } from '@angular/router';

@Component({
  selector: 'up-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly = false;
  user: {
    name: string,
    email: string,
    photoUrl: string,
  };

  userMenu: NbMenuItem[] = [{title: 'Log out'}];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private afa: AngularFireAuth,
    private nbMenuService: NbMenuService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    const {xl} = this.breakpointService.getBreakpointsMap();

    this.nbMenuService.onItemClick()
      .pipe(
        filter((menu) => menu.tag === 'userMenu' && menu.item.title === 'Log out' ),
      )
      .subscribe(async () => {
        await this.afa.signOut();
        this.router.navigate(['auth', 'login']);
      });

    this.afa.user.subscribe((user) => {
      if (user) {
        this.user = {
          name: user.displayName || user.email,
          email: user.email,
          photoUrl: user.photoURL,
        };
      } else {
        this.user = null;
      }

    });

    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
